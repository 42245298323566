@mixin hover-dots($top: 100%) {
  &:before {
    position: absolute;
    top: $top;
    left: 50%;
    color: transparent;
    content: '•';
    text-shadow: transparent 0px 0px;
    font-size: 1.2em;
    transform: translateX(-50%);
    pointer-events: none;
    transition: text-shadow 0.3s, color 0.3s;
  }
}

@mixin hover-dots-active() {
  &:before {
    color: $beige;
    text-shadow: 10px 0 $beige, -10px 0 $beige;
  }
}

.header__site {
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  top: 0;
  background: $white;
  z-index: 1040;
  border-bottom: 1px solid $gray-300;
  position: fixed;
  .primary {
    @media (min-width: 767px) {
      padding: 0rem 4rem;
    }

    position: relative;

    transition: $transition;
    width: 100%;

    display: flex;
    align-items: center;
  }
}

.toolbar-activated{
  .header__site {
    top: 46px;
  }
}

#id_index {
  .menu__products {
    height: 0;
  }
  .header__fixed {
    .menu__products {
      height: 50px;
    }
  }
}
#id_inscription_adherent {
  .menu__products {
    height: 0;
  }
  #wrap {
    padding-top: 120px !important;
  }
}
.menu__products {
  //position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  list-style-type: none;
  justify-content: center;
  border-top: 1px solid $gray-300;
  background: #f4f3f3;
  transition: $transition;
  margin-bottom: 0;
  height: 50px;
  overflow: hidden;
  align-items: center;
  li {
    margin-left: 10px;
    margin-right: 10px;
    a {
      color: $gray-800;
      font-size: 18px;
      position: relative;
      display: inline-block;
      @include hover-dots(50%);
      &:hover,
      &:focus {
        color: $beige_text;
        @include hover-dots-active();
      }
    }

    &.selected,
    &.ancestor {
      a {
        color: $beige_text;
      }
    }
  }
}
.logo {
  img {
    width: 150px;
    height: auto;
  }
}

.nav__site {
  width: 100%;

  &--top,
  &--bottom {
    > ul {
      display: flex;
      margin-bottom: 0;

      a {
        color: $gray-800;
        text-transform: uppercase;
        transition: $transition;
        font-family: $neris;

        &:hover {
          color: $beige_text;
        }
      }

      .selected,
      .ancestor {
        a {
          color: $beige_text;
        }
      }
    }
  }
  &--top {
    border-bottom: 1px solid #eee;
    ul {
      flex-direction: row-reverse;
    }
    a {
      font-size: 1.4rem;
      padding: 0.5rem 2rem;
      display: inline-block;
    }
    .pink {
      background: $beige;
      color: $white;
      a {
        color: $white;
      }
      &:hover {
        a {
          color: darken($beige_text, 20);
        }
      }
    }
  }
  &--bottom {
    > ul {
      padding: 20px 10px;
      justify-content: center;
      li {
        padding: 10px;
        margin-left: 20px;
        margin-right: 20px;

        a {
          position: relative;
          font-size: 18px;
          @include hover-dots();

          &:hover,
          &:focus {
            @include hover-dots-active();
          }
        }

        &.selected,
        &.ancestor {
          a {
            color: $beige_text;
          }
        }
      }
    }
  }
}

@keyframes translate {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

.sidr {
  background: darken($white, 4);
  display: block;
  position: fixed;
  top: 88px;
  bottom: 0;
  height: 100%;
  z-index: 9999;
  width: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  &.left {
    left: -260px;
    right: auto;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      border-bottom: 1px solid darken($white, 10);
    }
    a {
      display: block;
      padding: 1rem;
      color: $black;
      font-family: $neris;
    }
  }
}
.sidr .sidr-inner {
  margin-bottom: 0;
  padding-bottom: 0;
  ul {
    margin-bottom: 0;
  }
  &:first-child {
    ul {
      border-bottom: 0;
    }
  }
  &:last-child {
    margin-top: -1px;
  }
}
.sidr-class-list-unstyled {
  display: flex !important;
  flex-direction: column-reverse;
}
.sidr-class-pink {
  background: $beige;
  a {
    color: $white !important;
  }
}
.sidr-class-dropdown {
  position: relative;
  display: flex;
  .sidr-class-dropdown-toggle,
  .sidr-class-dropdown-menu {
    flex: 1;
  }
}

.sidr ul li.active > a,
.sidr ul li.active > span,
.sidr ul li.sidr-class-active > a,
.sidr ul li.sidr-class-active > span,
.sidr ul li:hover > a,
.sidr ul li:hover > span {
  box-shadow: none;
}
/*----------------
Responsive
--------------------*/
@media (max-width: 991px) {
  .header__site {
    .primary {
      padding: 1rem;
      justify-content: space-between;
      .logo {
        img {
          width: 80px;
        }
      }
    }

    .nav__site,
    .menu__products {
      visibility: hidden !important;
      display: none !important;
    }
  }
  .hamburglar {
    display: block;
  }
}

body#id_archive,
body#id_news {
  .menu__products {
    display: none;
  }
  #wrap {
    padding-top: 85px;
  }
}
