html,
body {
  height: 100%;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
  font-family: $neris_light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  display: flex;
  flex-direction: column;
  &:not(#id_index) {
    #wrap {
      padding-top: 165px;
    }
  }
}
address {
  font-family: $neris;
}
main{
  padding-bottom: 3rem;
}
#wrap {
  // margin-bottom: -220px;
  min-height: 100%;
  padding-top: 115px;
  flex: 1 0 auto;
}
#footer {
  flex-shrink: 0;
}
.icon {
  display: inline-block;
}

.arrow__right {
  @include sprite($sprite-arrow-right);
}
.filter {
  @include sprite($sprite-filter);
}

.bebe {
  @include sprite($sprite-bebe);
}
.homme {
  @include sprite($sprite-homme);
}
.femme {
  @include sprite($sprite-femme);
}
.enfant {
  @include sprite($sprite-enfant);
}

.target {
  i {
    margin-left: 0rem;
    transform: scale(0.7);
  }
}

a.show__more {
  position: relative;
  display: inline-block;
  margin-top: 1rem;
  color: $black;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 3rem;
  &::before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: $transition;
  }
  &::before {
    height: 2rem;
    left: 0;
    width: 0.4rem;
    background: $green_new;
  }
  &::after {
    @include sprite($sprite-arrow-right-black);
    right: 0;
  }
  &:hover,
  &:focus {
    color: $beige_text;
    font-weight: bold;
    &::after {
      transform: translateY(-50%) translateX(2rem);
    }
    &::before {
      background: $beige;
      height: 2.4rem;
    }
  }
}

.sticky__menu {
  position: sticky;
  top: 300px;
}
.dropdown-menu {
  font-size: 1.6rem;
}

.list__zibra {
  li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #eee;
    padding: 10px;
    span {
      flex: 1;
      &:first-child {
        flex: 2;
      }
    }
    &:nth-child(even) {
      background: #eee;
    }
    &.first {
      background: $green_new;
      font-family: $neris;
      color: $white;
    }
    a.certification-details, a.facture-details {
      display: flex;
      width: 100%;
      justify-content: space-between;
      span:first-child {
        color: $black;
      }
    }
  }

  margin-bottom: 0;

  &.list__acounts, &.list__acounts__description{
    li {
      span {
        &:first-child {
          flex: 1;
        }
      }
    }
  }
}


.unstyled_a {
  display: inline-block !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($green_new, 0.6);
  color: $white;
  font-size: 3rem;
  img {
    width: 130px;
    height: auto;
  }
}

.purple {
  color: $green_new;
}

.card {
  padding: 10px;
  display: flex;
  border-radius: 4px;
  text-align: center;
  color: $white;
  text-transform: capitalize;
  background: $beige;
  font-family: $neris;
}

.list__prod {
  display: flex;
  flex-wrap: wrap;
  li {
    padding-left: 30px;
    margin-bottom: 10px;
    position: relative;
    flex: 1 0 33%;
    &::before {
      content: '';
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 8px;
      border: 2px solid $beige;
      left: 10px;
      top: 8px;
    }
  }
  a {
    color: $text-color;
    font-family: $neris;
    &:hover {
      color: $beige_text;
    }
  }
}

.notifications {
  position: fixed;
  top: 200px;
  left: 50%;
  z-index: 1040;
  width: 660px;
  margin-left: -330px;

  .alert {
    margin-bottom: 0;
    font-family: $neris;
    padding: 40px;
    &.success {
      background: #2ecc71;
      color: $white;
      button {
        color: $white;
      }
    }
    &.warning {
      border-color: #ffeeba;
      background: #fff3cd;
      color: #856404;
      button {
        color: #856404;
      }
    }
    &.failed {
      color: $white;
      background: #e74c3c;
      button {
        color: $white;
      }
    }
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}
.teaser {
  transition: $transition;
  a {
    color: inherit;
    text-decoration: none;
  }
  .teaser-content {
    padding: 20px;
    background: $gray-100;
  }
  .title__teaser {
    font-size: 2.4rem;
    color: $green_new;
    p {
      transition: $transition;
    }
  }
  &:hover,
  &:focus {
    box-shadow: 0px 0px 10px 1px rgba($black, 0.2);
    a {
      text-decoration: none !important;
    }
  }
}

.img-responsive {
  @extend .img-fluid;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  margin-right: 0;
  background: $beige;
  z-index: 1040;
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 30px;
  text-align: center;
  line-height: 16px;
  right: -10px;
  top: -16px;
  color: $white;
}

.select2-container--focus {
  .selection::after {
    display: none !important;
  }
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #222 transparent transparent transparent !important;
  border-style: solid !important;
  border-width: 5px 4px 0 4px !important;
  height: 0 !important;
  left: 50% !important;
  margin-left: -8px !important;
  margin-top: 4px !important;
  position: absolute !important;
  top: 50% !important;
  width: 0 !important;
}
.select2-search__field{
  min-height: 0 !important;
}

.dropdown-toggle {
  cursor: pointer;
}

iframe {
  max-width: 100%;
}

.u-accordion__header {
  margin-bottom: 0;
  h5 {
    color: $gray-700;
    font-size: 2.2rem;
  }
  h5 a {
    justify-content: space-between;
    color: inherit;
    &.collapsed {
      .fa-angle-up {
        display: none;
      }
    }
    &:not(.collapsed) {
      .fa-angle-down {
        display: none;
      }
    }
  }
  h5 a span {
    &:not(.title) {
      width: 30px;
      //border-left: 1px solid rgba(0, 0, 0, 0.25);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
    }
    &.title {
      display: block;
      padding: 1.75rem 1.25rem;
    }
  }
}

.u-accordion__body {
  padding: 1.5rem;
  padding-top: 0;
  //border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.u-card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0rem;
}
.u-accordion {
  .u-card:last-child {
    border-bottom: 0;
  }
}

@include media-breakpoint-down(xs) {
  .hidden-xs{
    display: none;
  }
}

@include media-breakpoint-only(sm) {
  .hidden-sm{
    display: none;
  }
}
