.form__title{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin:20px 0;
    h1{
        margin: 0;
    }
}
.frm__search{
    @include media-breakpoint-down(sm) {
        min-width: 100%;
    }
    .wrapper{
        display: flex;
        justify-content: end;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            margin-top: 1rem;
        }
        input{
            height: 50px;
            padding: 1rem 2rem;
            @include media-breakpoint-down(sm) {
                flex: 1;
                display: block;
                margin-bottom: 0.5rem;
                min-width: 100%;
            }
            &[type="search"]{
                border: 1px solid #eee;
            }
            &[type="submit"]{
                background-color: theme-color('primary');
                color: white;
                cursor: pointer;
                &:hover, &:active{
                    background-color:darken( theme-color('primary'), 10);
                }
            }
        }
    }
}

.plugin__search{
    @include make-col-ready();
    .search-results{
        ul.result-list{
            list-style-type: none;
            padding-left: 0;
            font-size: 110%;

            li{
                padding: 1rem;
                border: 1px solid #eee;
                margin-bottom: 1em;
                &:nth-child(even){
                    background-color: #f5F5F5;
                }
                h3{
                    a{
                        color: inherit;
                    }
                }
                .highlighted{
                    font-weight: bold;
                    font-family: $neris;
                    font-size: 120%;
                    position: relative;
                    &::before {
                        /* Highlight color */
                        background-color: rgba($green_new, .3);

                        content: "";
                        position: absolute;
                        width: calc(100% + 4px);
                        height: 60%;
                        left: -2px;
                        bottom: 0;
                        z-index: -1;
                        transform: rotate(-2deg);
                    }
                }
            }
        }
    }
    .pagenav{
        margin-top: 2rem;
        padding-top: 1rem;
        border-top: 1px solid #eee;
        .pagination{
            display: flex;
            justify-content: center;
            list-style: none;
            padding-left: 0;
            li{
                a, span{
                    padding: 0.5rem 1rem;
                    display: inline-block;
                }

                &.page-counter{
                    padding-top: 2px;
                }
            }
            a{
                background-color: theme-color('primary');
                color: white;
            }
        }
    }
}

.side-bar{
    .frm{
        margin-top: 1rem;
        margin-bottom: 1rem;
        input{
            display: inline-block;
            margin-bottom: 0.5rem;
            width: 100%;
            padding: 1rem;
            padding: 1rem 1.5rem;
            &[type="search"]{
                border: 1px solid #ccc;
            }
        }
    }
}
