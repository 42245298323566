@media (max-width: 991px) {
  #wrap {
    margin-top: 84px;
  }
}

/*---
XS
-----*/
@include media-breakpoint-down(xs) {
  .detail {
    padding: 20px;
  }
  /* typos */
  h1 {
    font-size: 8vw;
  }
  h2 {
    font-size: 22px !important;
  }
  h3 {
    font-size: 20px;
  }
  .nav-tabs .nav-item {
    font-size: 1.2rem;
  }

  #wrap {
    overflow: hidden;
  }
  .breadcrumb, .breadcrumb-block {
    display: none;
  }

  .footer {
    background-color: $gray-100;
    // height: 500px;
  }
  #push {
    // height: 500px;
  }
  #wrap {
    // margin-bottom: -500px;
  }
  .footer__site {
    padding: 0 !important;
    padding-top: 20px !important;
    flex-direction: column;
    img {
      max-width: 100px;
      margin-bottom: 20px;
    }
    .nav {
      text-align: center;
      margin-bottom: 20px;
    }
    .social__links {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      ul {
        margin-bottom: 0;
      }
    }
  }
  .legal-notices {
    padding: 10px;
    &::before {
      display: none;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $gray-200;
        border-top: 1px solid $white;
        &:first-child {
          border-top: 0;
        }
      }
    }
  }
  /***----Carousel -----***/
  #id_index {
    #promotion_image {
      height: 360px;
      overflow: hidden;
      background: $green_new;
      .carousel-indicators {
        bottom: 10px;
      }
    }
    .carousel {
      .overlay {
        position: static;
        background: $green_new;
        padding-bottom: 3rem;
        h2 {
          margin-bottom: 1rem;
          font-size: 2rem !important;
          line-height: 2rem !important;
          font-family: $neris;
        }
      }
      .carousel__link {
        padding: 1rem;
        background: transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        text-indent: -99999px;
      }
    }
    .carousel-inner .slide-wrapper {
      padding-bottom: 0;
    }
    .promotion-image {
      height: 250px;
    }
    .carousel-inner .slide-wrapper {
      height: auto;
    }
  }
  /***----end Carousel -----***/
  #wrap,
  body:not(#id_index) #wrap {
    padding-top: 0;
  }
  .results_list__wrapper .sidebar {
    position: static;
  }

  .news__items .item {
    padding: 0;
    margin-bottom: 2rem;
  }

  .dossiers {
    background: $gray-200;
  }
  .logo__title {
    display: none;
  }
  section.section {
    padding: 2rem 0;
  }
  .cosmebio {
    margin-top: 0;
    h2 {
      width: 100%;
      font-size: 24px;
    }
    .img__side {
      display: none;
    }
  }
  .dossiers {
    .dossiers__items {
      flex-direction: column;
      .primary {
        min-height: auto;
      }
      .item {
        padding: 0;
        margin-bottom: 20px;
      }
      .dossier__heading {
        padding: 2rem;
      }
    }
    .sacondary__item {
      min-height: auto;
    }
  }
  .last__products {
    .intro {
      margin-bottom: 0;
    }
    .product {
      background: $white;
      .product__name,
      .brand__name {
        padding: 10px;
        text-align: center;
        display: block !important;
      }
    }
  }

  .news__items {
    .news__heading {
      padding: 1rem;
      a {
        position: relative;
        right: unset;
        bottom: unset;
      }
      &::after {
        display: none;
      }
    }
    .item {
      min-width: 100%;
      background-color: $white;
      border: 1px solid $gray-200;
      img {
        width: 100%;
      }
      h3 {
        margin-bottom: 0;
        text-align: center;
        min-height: auto;
        padding: 2rem 1rem;
        display: block;
      }
    }
    .link {
      transform: translate(0px, 0px);
      margin-top: 10px;
      margin-top: 0;
      width: 100%;
      text-align: center;
    }
  }

  .highlight:not(.country) {
    display: none;
  }
  .results_list__wrapper {
    flex-direction: column;
  }
  .sidebar {
    width: 100% !important;
  }
  .results_list {
    .item {
      max-width: 100%;
      flex: 1 0 45%;
      margin: 2.5%;
      margin-bottom: 15px;
      &:nth-child(3n + 1) {
        margin-right: 2.5%;
      }
      .img__wrapper {
        min-height: auto;
      }
      .content h3 {
        color: $beige_text;
        font-size: 2rem;
      }
    }
    .pagination {
      flex-wrap: wrap;
      li {
        margin: 0;
        padding: 0.5rem;
      }
    }
  }
  .go__back {
    display: block;
    color: $white;
    background: $beige;
    padding: 10px;
    text-align: center;
  }
  .product__detail {
    .detail {
      padding-left: 0;
      border-left: 0;
      .img__wrapper {
        border: 1px solid #eee;
      }
    }
    .h1 {
      font-size: 2.4rem;
      margin-top: 0;
    }
  }
  .product__detail__image {
    margin-bottom: 2rem;
    .product__detail h2 {
      margin-top: 0;
    }
    .logo__label {
      display: none !important;
    }
  }

  .result__list {
    padding-left: 0;
    width: 100% !important;
  }
  .search-list__result {
    .pagination {
      flex-wrap: wrap;
    }
    .results_list {
      .item {
        min-width: 100%;
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }
  }
  .why__cosmebio {
    display: none;
  }
  .results_list__wrapper {
    #show__more__filters {
      margin-top: 20px;
    }
    .sidebar {
      width: 100% !important;
      //transform: translateX(-100%);
      transition: all 0.3s linear;
      //position: fixed;
      //left: 0;
      //top: 160px;
      background: $white;
      z-index: 1;
      .form-group {
        .btn.name, .btn.text {
          text-align: center;
          display: inline-block;
        }
        &.last {
          display: none;
        }
      }
      &.is__visible {
        .form-group {
          &.last {
            display: block;
          }
        }
      }
    }
  }
  .product__image {
    position: static;
    z-index: 1;
    margin-top: 20px;
    &:before {
      display: none;
    }
  }

  .product__detail {
    .breadcrumb {
      display: none;
    }
  }
  .btn {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  #id_news {
    h1 {
      padding: 20px;
      margin-bottom: 0px;
      margin-top: 10px;
      font-size: 8vw;
    }
  }

  .news--content {
    flex-direction: column;
  }

  #id_archive {
    .side-bar {
      margin-bottom: 20px;
      position: static;
    }
  }
  #id_page_menu,
  .product__detail,
  .products__page {
    header {
      margin-bottom: 0;
    }
  }
  .results_list__wrapper {
    padding-top: 0;
  }

  .product__detail {
    h1 {
      margin-top: 10px;
      padding-bottom: 20px;
      &::after {
        bottom: 10px;
      }
    }
    .badge {
      margin-bottom: 20px;
    }
    .badge-primary{
      background-color: $beige !important;
      .product-subcategory-link{
        color: $gray-900;
      }
    }
  }
  .brand__detail {
    padding: 0;
    h2 {
      margin-top: 10px;
      padding-bottom: 20px;
      &::after {
        bottom: 10px;
      }
    }
    h3 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .products__brand .product {
    flex: 1 0 48%;
    margin-top: 10px;
  }
  .marque__detail {
    .detail {
      padding: 1rem;
    }
  }
  .detail__address {
    flex-wrap: wrap;
    address,
    ul {
      min-width: 100%;
    }

  }
  .pagination {
    flex-wrap: wrap;
  }
  .result__list__detail {
    position: absolute;
    display: none;
  }
  .overflow__hidden {
    overflow: inherit;
  }
  .result__list {
    .multicolumn {
      padding: 20px;
    }
  }
  .result__list__page {
    header {
      background: $white;
      z-index: 1040;
    }
    .sidebar {
      position: static;
    }
    main {
      overflow: scroll;
      max-height: 100vh;
      height: 100% !important;
      padding-bottom: 300px;
    }
    #id_criteria_form {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      .form-group {
        margin-bottom: 2rem;
      }
      #id_name {
        min-width: 100%;
      }
    }
  }
  .subscriber__title {
    flex-direction: column;
    text-align: center;
    h1 {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
}

/*
sm
 */
@media (min-width: 576px) and (max-width: 769px) {
  .promotion-image {
    height: 360px;
  }
  .header__site {
    z-index: 1040 !important;
  }
  #wrap {
    padding-top: 0 !important;
  }
  .tabs__wrapper {
    max-width: 100% !important;
    flex: 1 0 100%;
  }
  .hidden-sm {
    display: none;
  }
  .dossiers {
    .dossiers__items {
      display: block;
      @include clearfix();
      .item {
        min-width: 50%;
        width: 50%;
        &.first {
          min-width: 100%;
          padding: 0;
          margin-bottom: 20px;
        }
        &:not(.first) {
          float: left;
        }
        .sacondary__item {
          background-color: $beige;
          min-height: auto;
        }
        .primary {
          min-height: auto !important;
        }
        &.last {
          padding-top: 0;
        }
      }
    }
  }
  section.section {
    padding: 20px 0;
  }
  .last__products {
    clear: both;
    margin-top: 30px;
  }
  .news__items {
    display: block;
    .link {
      transform: translate(0, 0);
      width: 100%;
    }
    .item {
      width: 33%;
      float: left;
      &:first-child {
        min-width: 100%;
        margin-bottom: 10px;
        float: none;
      }
    }
    .news__heading {
      padding: 3rem;
      &::after {
        display: none;
      }
    }
  }
  .carousel__link {
    padding: 2rem;
  }
  .footer__site {
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    .nav {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      justify-content: center;
    }
  }
  .legal-notices {
    ul {
      text-align: center;
      li {
        &:first-child {
          display: block;
        }
      }
    }
  }
  // #wrap {
  //   margin-bottom: -500px;
  // }
  #push,
  .footer {
    // height: 500px;
  }
  .result__list__page {
    #wrap {
      padding-top: 0 !important;
    }
    .sidebar {
      position: inherit;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px !important;
      padding-top: 20px !important;
      #id_criteria_form {
        display: flex;
        align-items: flex-end;
        .form-group {
          margin-bottom: 0;
        }
        input[type='submit'] {
          height: 40px;
          line-height: 20px;
        }
      }
    }
  }
}

/*
md
*/

@media (min-width: 980px) and (max-width: 1300px) {
  .carousel-inner .slide-wrapper {
    height: 500px;
  }
  .promotion-image {
    height: 450px;
  }
  .nav__site--bottom > ul li {
    padding: 0;
    a {
      font-size: 1.6rem;
    }
  }
  .header__site .primary {
    padding: 0 1rem;
  }
  #wrap {
    padding-top: 110px;
  }
  .cosmebio {
    padding: 1rem 0;
    padding-top: 0;
    margin-top: 1rem;
  }
  .news__items {
    .link {
      transform: translate(0, 0);
      width: 100%;
    }
    .item {
      &:first-child {
        min-width: 100%;
        margin-bottom: 10px;
      }
      img {
        width: 100%;
      }
    }
    .news__heading {
      padding: 3rem;
      &::after {
        display: none;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .dossiers {
    .dossiers__items {
      flex-wrap: wrap;
      .primary {
        min-height: 1px;
      }
    }
  }
  .news__items {
    display: block;
    .link {
      transform: translate(0, 0);
      width: 100%;
    }
    .item {
      width: 33%;
      float: left;
      &:first-child {
        min-width: 100%;
        margin-bottom: 10px;
        float: none;
      }
    }
    .news__heading {
      padding: 3rem;
      &::after {
        display: none;
      }
    }
  }
  .footer__site {
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    .nav {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      justify-content: center;
    }
  }
  .legal-notices {
    ul {
      text-align: center;
    }
  }

  .menu__products {
    padding-left: 0;
  }
  .logo {
    margin-right: 10px;
  }
}
