@mixin btn-theme($btn-color, $text-color) {
    background-color: $btn-color;
    border-color: darken($btn-color, 10);
    color: $text-color;
    display: inline-flex;
    padding: 1rem;
    align-items: center;
    font-size: 16px;
    border-radius: 2px;
    &:hover {
        background-color: darken($btn-color, 10);
        color: $text-color;
    }
}

.btn {
    @include btn-theme($green_new, $white);
    padding-left: 3rem;
    padding-right: 3rem;
}

.btn.btn-secondary {
    @include btn-theme($beige, $white);
}

button.btn,
button,
input[type="submit"] {
    border: 0;
    appearance: none;
    i {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.download {
    @include btn-theme($green_new, $white);
}

.confirm {
    @include btn-theme($beige, $white);
}

.btn__add {
    @include btn-theme($beige, $white);
}
