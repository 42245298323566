.pagination{
    padding-right: 0 !important;
    li:not(.page-counter){
        box-shadow: 0 2px 35px 0 rgba(0,0,0,0.10);
        background: $white;
        margin: .5rem;
        min-width: 4rem;
        text-align: center;
        transition:$transition;
        &:last-child{
            margin-right: 0;
        }
        a,
        .pagination-disabled{
            display: block;
            padding: 1rem;
            color: $text-color;
        }
        &:hover, &:focus, &.active{
            background: $beige;
            a{
                color: $white;
            }
        }
    }
}
