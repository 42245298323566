.video .cookieoptin-notice {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 1em;
    color: white;
    background-color: black;

    .btn {
        background-color: $green_new;
        border-color: $green_new;
        color: $white;
        white-space: normal;
        &:hover {
            background-color: $beige !important;
            border-color: $beige !important;
        }
    }

    a {
        color: $beige_text;
    }
}

.video .video-player {
    padding: 1rem;
}

.cookieoptin-hide {
    display: none;
}

#klaro .klaro {
    label {
        display: inline;
    }
    .cm-modal,
    .cookie-notice {
        background: $gray-800;

        a {
            color: $green_new;
        }

        .cm-btn {
            box-shadow: none;
            padding: 1rem 2rem;
            border: solid 1px transparent;
            background: transparent;
            cursor: pointer;

            &:not(.cm-btn-info){
                &:hover {
                    border-color: $beige;
                    color: $beige_text;
                }
            }
            &.cm-btn-success {
                border: solid 1px $green_new;
                background: $green_new;

                &:hover {
                    background-color: transparent;
                    color: $green_new;
                    border: solid 1px $green_new;
                }
            }

            &.cm-btn-info {
                color: $green_new;
                padding-left: 0;
            }
        }
    }

    .cookie-notice {
        max-width: 400px;
    }

    .cookie-modal .slider {
        box-shadow: none;
    }
}
