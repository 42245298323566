// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-arrow-right-black-name: 'sprite_arrow-right-black';
$sprite-arrow-right-black-x: 369px;
$sprite-arrow-right-black-y: 14px;
$sprite-arrow-right-black-offset-x: -369px;
$sprite-arrow-right-black-offset-y: -14px;
$sprite-arrow-right-black-width: 19px;
$sprite-arrow-right-black-height: 14px;
$sprite-arrow-right-black-total-width: 1371px;
$sprite-arrow-right-black-total-height: 267px;
$sprite-arrow-right-black-image: '../img/sprite.png';
$sprite-arrow-right-black: (369px, 14px, -369px, -14px, 19px, 14px, 1371px, 267px, '../img/sprite.png', 'sprite_arrow-right-black', );
$sprite-arrow-right-name: 'sprite_arrow-right';
$sprite-arrow-right-x: 388px;
$sprite-arrow-right-y: 14px;
$sprite-arrow-right-offset-x: -388px;
$sprite-arrow-right-offset-y: -14px;
$sprite-arrow-right-width: 19px;
$sprite-arrow-right-height: 14px;
$sprite-arrow-right-total-width: 1371px;
$sprite-arrow-right-total-height: 267px;
$sprite-arrow-right-image: '../img/sprite.png';
$sprite-arrow-right: (388px, 14px, -388px, -14px, 19px, 14px, 1371px, 267px, '../img/sprite.png', 'sprite_arrow-right', );
$sprite-bebe-name: 'sprite_bebe';
$sprite-bebe-x: 204px;
$sprite-bebe-y: 14px;
$sprite-bebe-offset-x: -204px;
$sprite-bebe-offset-y: -14px;
$sprite-bebe-width: 45px;
$sprite-bebe-height: 41px;
$sprite-bebe-total-width: 1371px;
$sprite-bebe-total-height: 267px;
$sprite-bebe-image: '../img/sprite.png';
$sprite-bebe: (204px, 14px, -204px, -14px, 45px, 41px, 1371px, 267px, '../img/sprite.png', 'sprite_bebe', );
$sprite-caret-name: 'sprite_caret';
$sprite-caret-x: 448px;
$sprite-caret-y: 14px;
$sprite-caret-offset-x: -448px;
$sprite-caret-offset-y: -14px;
$sprite-caret-width: 10px;
$sprite-caret-height: 6px;
$sprite-caret-total-width: 1371px;
$sprite-caret-total-height: 267px;
$sprite-caret-image: '../img/sprite.png';
$sprite-caret: (448px, 14px, -448px, -14px, 10px, 6px, 1371px, 267px, '../img/sprite.png', 'sprite_caret', );
$sprite-check-name: 'sprite_check';
$sprite-check-x: 438px;
$sprite-check-y: 14px;
$sprite-check-offset-x: -438px;
$sprite-check-offset-y: -14px;
$sprite-check-width: 10px;
$sprite-check-height: 8px;
$sprite-check-total-width: 1371px;
$sprite-check-total-height: 267px;
$sprite-check-image: '../img/sprite.png';
$sprite-check: (438px, 14px, -438px, -14px, 10px, 8px, 1371px, 267px, '../img/sprite.png', 'sprite_check', );
$sprite-enfant-name: 'sprite_enfant';
$sprite-enfant-x: 306px;
$sprite-enfant-y: 14px;
$sprite-enfant-offset-x: -306px;
$sprite-enfant-offset-y: -14px;
$sprite-enfant-width: 25px;
$sprite-enfant-height: 41px;
$sprite-enfant-total-width: 1371px;
$sprite-enfant-total-height: 267px;
$sprite-enfant-image: '../img/sprite.png';
$sprite-enfant: (306px, 14px, -306px, -14px, 25px, 41px, 1371px, 267px, '../img/sprite.png', 'sprite_enfant', );
$sprite-fax-name: 'sprite_fax';
$sprite-fax-x: 423px;
$sprite-fax-y: 14px;
$sprite-fax-offset-x: -423px;
$sprite-fax-offset-y: -14px;
$sprite-fax-width: 15px;
$sprite-fax-height: 13px;
$sprite-fax-total-width: 1371px;
$sprite-fax-total-height: 267px;
$sprite-fax-image: '../img/sprite.png';
$sprite-fax: (423px, 14px, -423px, -14px, 15px, 13px, 1371px, 267px, '../img/sprite.png', 'sprite_fax', );
$sprite-femme-name: 'sprite_femme';
$sprite-femme-x: 279px;
$sprite-femme-y: 14px;
$sprite-femme-offset-x: -279px;
$sprite-femme-offset-y: -14px;
$sprite-femme-width: 27px;
$sprite-femme-height: 41px;
$sprite-femme-total-width: 1371px;
$sprite-femme-total-height: 267px;
$sprite-femme-image: '../img/sprite.png';
$sprite-femme: (279px, 14px, -279px, -14px, 27px, 41px, 1371px, 267px, '../img/sprite.png', 'sprite_femme', );
$sprite-filter-name: 'sprite_filter';
$sprite-filter-x: 331px;
$sprite-filter-y: 14px;
$sprite-filter-offset-x: -331px;
$sprite-filter-offset-y: -14px;
$sprite-filter-width: 22px;
$sprite-filter-height: 20px;
$sprite-filter-total-width: 1371px;
$sprite-filter-total-height: 267px;
$sprite-filter-image: '../img/sprite.png';
$sprite-filter: (331px, 14px, -331px, -14px, 22px, 20px, 1371px, 267px, '../img/sprite.png', 'sprite_filter', );
$sprite-homme-name: 'sprite_homme';
$sprite-homme-x: 249px;
$sprite-homme-y: 14px;
$sprite-homme-offset-x: -249px;
$sprite-homme-offset-y: -14px;
$sprite-homme-width: 30px;
$sprite-homme-height: 41px;
$sprite-homme-total-width: 1371px;
$sprite-homme-total-height: 267px;
$sprite-homme-image: '../img/sprite.png';
$sprite-homme: (249px, 14px, -249px, -14px, 30px, 41px, 1371px, 267px, '../img/sprite.png', 'sprite_homme', );
$sprite-mail-name: 'sprite_mail';
$sprite-mail-x: 407px;
$sprite-mail-y: 14px;
$sprite-mail-offset-x: -407px;
$sprite-mail-offset-y: -14px;
$sprite-mail-width: 16px;
$sprite-mail-height: 13px;
$sprite-mail-total-width: 1371px;
$sprite-mail-total-height: 267px;
$sprite-mail-image: '../img/sprite.png';
$sprite-mail: (407px, 14px, -407px, -14px, 16px, 13px, 1371px, 267px, '../img/sprite.png', 'sprite_mail', );
$sprite-map-pin-name: 'sprite_map-pin';
$sprite-map-pin-x: 353px;
$sprite-map-pin-y: 14px;
$sprite-map-pin-offset-x: -353px;
$sprite-map-pin-offset-y: -14px;
$sprite-map-pin-width: 16px;
$sprite-map-pin-height: 19px;
$sprite-map-pin-total-width: 1371px;
$sprite-map-pin-total-height: 267px;
$sprite-map-pin-image: '../img/sprite.png';
$sprite-map-pin: (353px, 14px, -353px, -14px, 16px, 19px, 1371px, 267px, '../img/sprite.png', 'sprite_map-pin', );
$sprite-monitor-name: 'sprite_monitor';
$sprite-monitor-x: 331px;
$sprite-monitor-y: 34px;
$sprite-monitor-offset-x: -331px;
$sprite-monitor-offset-y: -34px;
$sprite-monitor-width: 16px;
$sprite-monitor-height: 15px;
$sprite-monitor-total-width: 1371px;
$sprite-monitor-total-height: 267px;
$sprite-monitor-image: '../img/sprite.png';
$sprite-monitor: (331px, 34px, -331px, -34px, 16px, 15px, 1371px, 267px, '../img/sprite.png', 'sprite_monitor', );
$sprite-pattern-name: 'sprite_pattern';
$sprite-pattern-x: 0px;
$sprite-pattern-y: 0px;
$sprite-pattern-offset-x: 0px;
$sprite-pattern-offset-y: 0px;
$sprite-pattern-width: 204px;
$sprite-pattern-height: 267px;
$sprite-pattern-total-width: 1371px;
$sprite-pattern-total-height: 267px;
$sprite-pattern-image: '../img/sprite.png';
$sprite-pattern: (0px, 0px, 0px, 0px, 204px, 267px, 1371px, 267px, '../img/sprite.png', 'sprite_pattern', );
$sprite-radio-name: 'sprite_radio';
$sprite-radio-x: 458px;
$sprite-radio-y: 14px;
$sprite-radio-offset-x: -458px;
$sprite-radio-offset-y: -14px;
$sprite-radio-width: 4px;
$sprite-radio-height: 4px;
$sprite-radio-total-width: 1371px;
$sprite-radio-total-height: 267px;
$sprite-radio-image: '../img/sprite.png';
$sprite-radio: (458px, 14px, -458px, -14px, 4px, 4px, 1371px, 267px, '../img/sprite.png', 'sprite_radio', );
$sprite-tel-name: 'sprite_tel';
$sprite-tel-x: 347px;
$sprite-tel-y: 34px;
$sprite-tel-offset-x: -347px;
$sprite-tel-offset-y: -34px;
$sprite-tel-width: 15px;
$sprite-tel-height: 15px;
$sprite-tel-total-width: 1371px;
$sprite-tel-total-height: 267px;
$sprite-tel-image: '../img/sprite.png';
$sprite-tel: (347px, 34px, -347px, -34px, 15px, 15px, 1371px, 267px, '../img/sprite.png', 'sprite_tel', );
$sprite-waves-name: 'sprite_waves';
$sprite-waves-x: 204px;
$sprite-waves-y: 0px;
$sprite-waves-offset-x: -204px;
$sprite-waves-offset-y: 0px;
$sprite-waves-width: 1167px;
$sprite-waves-height: 14px;
$sprite-waves-total-width: 1371px;
$sprite-waves-total-height: 267px;
$sprite-waves-image: '../img/sprite.png';
$sprite-waves: (204px, 0px, -204px, 0px, 1167px, 14px, 1371px, 267px, '../img/sprite.png', 'sprite_waves', );
$spritesheet-width: 1371px;
$spritesheet-height: 267px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($sprite-arrow-right-black, $sprite-arrow-right, $sprite-bebe, $sprite-caret, $sprite-check, $sprite-enfant, $sprite-fax, $sprite-femme, $sprite-filter, $sprite-homme, $sprite-mail, $sprite-map-pin, $sprite-monitor, $sprite-pattern, $sprite-radio, $sprite-tel, $sprite-waves, );
$spritesheet: (1371px, 267px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
