$fa-font-path: "../fonts/fontawesome";

@font-face {
    font-family: "gunny";
    src: url("../fonts/gunny/gnyrwn971.woff2") format("woff2"),
         url("../fonts/gunny/gnyrwn971.woff") format("woff");
    font-style:   normal;
    font-display: swap;

}
$gunny:   "gunny", Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

@font-face {
    font-family: "neris";
    src: url("../fonts/neris/neris-semiBold.woff2") format("woff2"),
         url("../fonts/neris/neris-semiBold.woff") format("woff");
    font-style:   normal;
    font-display: swap;
}
$neris:   "neris", Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

@font-face {
    font-family: "neris light";
    src: url("../fonts/neris/neris-light.woff2") format("woff2"),
         url("../fonts/neris/neris-light.woff") format("woff");
    font-style:   normal;
    font-display: swap;
}
$neris_light:   "neris light", Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

@font-face {
    font-family: "neris light italic";
    src: url("../fonts/neris/neris-lightitalic.woff2") format("woff2"),
         url("../fonts/neris/neris-lightitalic.woff") format("woff");
    font-style:   italic;
    font-display: swap;
}
$neris_light_italic:   "neris light italic", Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #1C1C1B !default;


$grays: (
    100: $gray-100,
         200: $gray-200,
         300: $gray-300,
         400: $gray-400,
         500: $gray-500,
         600: $gray-600,
         700: $gray-700,
         800: $gray-800,
         900: $gray-900
) !default;

$blue:    #0B6589 !default;
$indigo:  #6610f2 !default;
$purple:  #8B669D !default;
$mauve :  #E3DBE0 !default;
$pink:    #D874AC !default;
$red:     #e74c3c !default;
$orange:  #F39200 !default;
$yellow:  #E0BC83 !default;
$green:   #007C31 !default;
$teal:    #20c997 !default;
$cyan:    #55BBB3 !default;
$green_new: #409B65 !default;
$beige: #DBB999 !default;
$beige_text: #C29A74 !default;
$green_light: #80BD9A !default;
$green_extralight: #E9F4EE !default;
$green_dark: #007E32 !default;


$colors: (
    blue: $blue,
          indigo: $indigo,
            purple: $purple,
            mauve: $mauve,
           pink: $beige,
          red: $red,
         orange: $orange,
            yellow: $yellow,
            green: $green,
           teal: $teal,
          cyan: $cyan,
          white: $white,
           gray: $gray-600,
          gray-dark: $gray-800
) !default;

$theme-colors: (
    primary: $beige_text,
             secondary: $gray-600,
               success: $green,
             info: $cyan,
          warning: $yellow,
             danger: $red,
            light: $gray-100,
           dark: $gray-800
) !default;

$text-color: #343434;
$transition : all .3s ease;

