.img-thumbnail-wrapper {
    .img-thumbnail-img {
        position: relative;
    }

    .img-author-icon {
        @extend .fa;
        @extend .fa-info-circle;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: .5em;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $green_new;
        }
    }

    .img-desc {
        font-size: .8em;
        font-style: italic;
    }

    img {
        @extend .img-fluid;
    }

    &.img-align-right {
        float: right;
    }

    &.img-align-left {
        float: left;
    }

    &.img-align-center {
        text-align: center;
    }
}
