.breadcrumb{
    width: 100%;
    display: flex;
    background-color: transparent;
    border-bottom: 1px solid $gray-200;

    .breadcrumb-item+.breadcrumb-item::before{
        display:none;
    }

    li{
        padding-right: 10px;
        padding-right: 10px;
        position: relative;
        a{
            display: block;
            position: relative;
            padding-right: 20px;
            &::after{
                content: ">";
                position: absolute;
                right: 0px;
                bottom: 0;
            }
        }
    }
}
