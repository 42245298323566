/*
    ----Home page
*/

#id_index {
  h2 {
    font-size: 4rem;
  }
}

.cosmebio {
  padding: 4rem 0;
  margin-top: 3rem;

  @include media-breakpoint-up(xl) {
    .container {
      min-width: 1200px;
    }
  }

  .container .title {
    align-items: center;
    margin-bottom: 3rem;

    img {
      width: 150px;
      margin-right: 3rem;
    }
  }

  .intro {
    font-family: $gunny;
    font-size: 2.4rem;
    color: lighten($black, 20);
  }

  .tab-pane {
    padding-top: 4rem;
  }

  .sign {
    margin-top: 2rem;
  }

  .nav-tabs {
    display: flex;
    flex-wrap: nowrap;

    .nav-item {
      .nav-link {
        color: $black;

        &.active,
        &:hover,
        &:focus {
          color: $beige_text;
          border-color: transparent;
          position: relative;
        }

        &.active {
          &:after {
            position: absolute;
            content: "";
            height: 3px;
            width: 100%;
            left: 0;
            bottom: -3px;
            border-radius: 2px;
            background: $beige;
          }
        }
      }
    }
  }

  figure {
    margin-right: 3rem;
  }

  .img__side {
    min-height: 400px;
    background-image: url(../img/cosmetiques-plantes-site-cosmebio_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    &--text {
      padding: 2rem;
      min-height: 270px;
      width: 240px;
      background: $beige;
      position: absolute;
      right: 15px;
      top: 30px;
      color: $white;
      font-family: $gunny;
      font-size: 3.6rem;
      line-height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:after {
        position: absolute;
        content: "";
        width: 95px;
        height: 90px;
        background-image: url(../img/plante.png);
        background-size: contain;
        background-repeat: no-repeat;
        bottom: -45px;
        left: -45px;
      }
    }
  }
}

.last__products {
  background: $green_extralight;
  padding: 4rem;

  h2 {
    color: $green_dark;
  }

  h3 {
    font-family: $gunny;
    font-size: 3rem;
  }

  .intro {
    margin-bottom: 3rem;

    p {
      max-width: 800px;
      margin: 0 auto;
      margin-top: 2rem;
      color: $gray-700;
    }
  }

  .product {
    transition: $transition;

    &:hover {
      transform: translateY(-10px);
    }

    h3 {
      font-family: $neris;
      font-size: 1.8rem;
    }
  }
}

.last__products,
.products__brand {
  .product {
    margin-top: 3rem;
    transition: $transition;
    position: relative;

    span.brand__name {
      font-family: $neris_light_italic;
      position: relative;
      display: inline-block;
    }

    .content {
      transition: $transition;
    }

    .img__wrapper {
      background: $white;
      text-align: center;
      padding: 0.5rem;
      transition: $transition;
    }

    .product__name {
      margin-top: 2rem;
      color: $beige_text;

      span {
        position: relative;
        display: inline-block;
      }
    }

    &:hover {
      .img__wrapper {
        box-shadow: 0px 1px 20px 5px rgba($black, 0.1);
      }

      .product__name span,
      .brand__name {
        &::before {
          transform: scale3d(1, 1, 1);
        }
      }
    }

    a {
      color: inherit;
    }
  }
}

.dossiers {
  padding: 4rem 1rem !important;

  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
    }
  }

  .secondary,
  .primary {
    display: block;
  }

  .dossiers__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1300px;
    margin: 0 auto;

    a {
      color: inherit;
    }

    .item {
      flex: 1 0 33%;
      padding: 3rem;
      background-size: cover;
      background-repeat: no-repeat;

      h3 {
        font-size: 2.5rem;
      }

      img {
        transition: $transition;
      }

      &.spotlight {
        padding: 0rem;
        background-position: center;
        background-size: 100%;

        &:hover {
          background-size: 200%;
        }
      }

      &.last {
        padding-right: 0;
      }

      &.first {
        padding-left: 0;
      }
    }

    .primary {
      position: relative;
      overflow: hidden;
      transition: $transition;
      min-height: 600px;
      background-color: $beige;

      .content {
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 10px;
        padding: 20px;
        background: rgba($black, 0.6);
        overflow: hidden;

        .title {
          color: $white;
          font-size: 3rem;
          font-family: $neris;
          margin-bottom: 2rem;
        }

        p {
          transform: translateY(200%);
          color: $white;
          transition: $transition;
          font-family: $neris;
          opacity: 0;
          visibility: hidden;
          height: 0;
        }
      }

      &:hover {
        .content p {
          height: auto;
          visibility: visible;
          opacity: 1;
          transform: translateY(0%);
        }

        img {
          transform: scale(2);
          opacity: 0.1;
        }
      }
    }

    .dossier__heading {
      background: $gray-200;
      padding: 6rem 4rem;
      position: relative;

      h2 {
        margin-bottom: 3rem;
        position: relative;
      }

      p {
        font-family: $neris_light_italic;
        font-size: 2rem;
        position: relative;
      }

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        height: 100px;
        width: 140px;
        display: block;
        background-image: url(../img/logo_gris.png);
        background-blend-mode: luminosity;
        background-repeat: no-repeat;
        background-color: $gray-200;
        opacity: 0.1;
      }
    }
  }

  .sacondary__item {
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    transition: $transition;

    .h3__title {
      color: $white;
      font-size: 3rem;
      transition: $transition;
    }

    .content {
      padding: 2rem;
      min-height: 260px;
      color: $white;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      p {
        font-family: $neris;
      }
    }

    .bg {
      background: $green_light;
      margin-top: 2rem;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      .author {
        img {
          border-color: white;
        }
      }

      &:hover {
        background: $beige;
      }
    }

    &.img-bg {
      position: relative;
      overflow: hidden;
      background: $beige;

      .content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
      }

      &:hover {
        img.background__img {
          transform: scale(2);
          opacity: 0.1;
        }
      }
    }
  }

  .author {
    color: $white;
    font-family: $neris;

    img {
      border: 2px solid $green_new;
    }
  }
}

.news__items {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .item {
    flex: 1 0 25%;
    padding: 10px;
    position: relative;

    h3 {
      color: $green_new;
      min-height: 66px;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    .img-container {
      position: relative;

      .type {
        position: absolute;
        top: 10px;
        left: 10px;
        background: $white;
        border-radius: 2px;
        display: inline-block;
        padding: 0 10px;
      }
    }
  }

  .news__heading {
    background: $beige;
    padding: 6rem 3rem;
    padding-bottom: 12rem;
    color: $white;
    position: relative;
    background-image: url(../img/logo-white.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;

    &:after {
      content: "";
      position: absolute;
      width: 150px;
      height: 100%;
      top: 0;
      bottom: 0;
      right: -150px;
      background: $beige;
    }

    h2 {
      margin-bottom: 3rem;
    }

    p {
      font-size: 2rem;
      font-style: italic;
    }
  }

  .link {
    background: $green_new;
    padding: 10px 20px;
    color: $white;
    float: right;
    transform: translate(10px, -10px);
    min-width: 150px;

    i {
      margin-left: 20px;
    }
  }
}

section.news {
  background-color: $gray-100;
}

section.section {
  padding: 6rem 4rem;
}

.sidebar {
  position: sticky;
  top: 200px;
  h1{
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 2rem;

    label {
      display: block;
    }

    ul {
      label {
        font-family: $neris_light;
      }
    }
  }
  .select2{
    min-width: 100%;
  }
  .field__wrapper{
    &.grid{

      display: grid;
      grid-template-columns: 1fr 50px;
    }
    input[type="text"]{
      border-right: 0;;
      border-radius: 0;
    }
    .submit{
      background-color: $green_new;
      color: white;
      font-weight: bold;
    }
  }

  input:not([type]),
  select {
    width: 100%;
    max-height: 40px;
    display: block;
    border-radius: 0;
    line-height: 20px;
    background-color: transparent !important;

    .select2-selection {
      border-radius: 0 !important;
      border: 0;
    }
  }

  .select2-container--default .select2-selection--multiple {
    background: transparent !important;
    border-radius: 0 !important;
  }

  .selection {
    position: relative;
    display: block;
    background-color: white;

    &::after {

      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      pointer-events: none;
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
}

// .sidebar__title {
//   color: $beige_text;
//   font-family: $neris;
//   margin-bottom: 2rem;
// }


.select2-container--default .select2-results__option--highlighted[aria-selected]{
  background-color: $green_new !important;
}
.no__product {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

#id_page_menu,
.products__page,
.product__detail {
  header {
    border-bottom: 1px solid $gray-200;
    margin-bottom: 3rem; //box-shadow: 0 2px 4px 0 rgba(233,233,233,0.50);
  }
}

/*
    ----product page
*/

.result__list {
  padding-left: 20px;
}

.search-list__result-count {
  padding: 1rem;

  span {
    font-weight: bold;
    color: $beige_text;
  }
}

.results_list__wrapper {
  display: grid;
  gap: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  grid-template-columns: 30% 1fr;
  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;
  }
  @include media-breakpoint-only(md) {
    grid-template-columns: 40% 1fr;
  }
  gap: 1rem;
  .sidebar {

    ul {
      list-style-type: none;
      padding-left: 0;
    }
    &__wrapper{
      border: 1px solid rgba(theme-color('primary'), .6);
      background-color: rgba(theme-color('primary'), .1);
      padding: 2rem;
    }
  }

  .select2-selection {
    min-height: 40px;
  }
}

.why__cosmebio {
  padding: 2rem;
  padding-left: 20rem;
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  color: $white;
  background: $green_new;
  background-image: url(../img/logo_gris.png);
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-position: 40px 50%;

  h2 {
    font-size: 30px;
    margin-bottom: 2rem;
  }

  p {
    font-family: $neris;
  }
}
.blog_descr {
  margin-bottom: 3rem;
}
.results_list {
  display: flex;
  flex-wrap: wrap;

  .item {

    margin-bottom: 30px;

    a {
      color: inherit;
      display: block;
      background: $white;
      transition: all 0.5s ease-in-out;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      border: 1px solid $gray-300;
      height: 100%;
    }

    .img__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content {
      padding: 1rem;

      h3 {
        font-size: 1.6rem;
        font-family: $neris;
        text-transform: lowercase;

        &::first-letter {
          text-transform: capitalize;
        }
      }

      strong{
        font-size: 1.8rem;
        font-weight: 400;
        display: block;
      }

      span {
        font-family: $neris_light_italic;
        color: $gray-600;
      }
    }

    &:hover {
      box-shadow: 0 0px 1px 0 rgba($beige, 0.9);
    }
  }

  .pagination {
    min-width: 100%;
    padding: 1rem;
    margin-top: 2rem;
  }
}

.product__detail {
  h1,
  .h1 {
    color: $green_new;
    font-family: $neris_light;
    padding-bottom: 6rem;
    margin-bottom: 0rem;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 70px;
      height: 3px;
      background: $gray-200;
      bottom: 3rem;
      left: 0;
    }
  }

  h2 {
    font-size: 2rem;
    margin-top: 4rem;
    color: $beige_text;
  }

  h3 {
    font-size: 1.8rem;
    color: $green_new;
  }

  .detail {
    padding-left: 5rem;
    border-left: 1px solid $gray-200;

    .badge-primary{
      background-color: $beige;
      .product-subcategory-link{
        color: $gray-900;
      }
    }
  }
}

.product__image {
  position: relative;
  margin-top: 30px;

  &::before {
    content: "";
    position: absolute;
    height: 8rem;
    width: 100%;
    background: $green_new;
    top: 40%;
    left: 0;
    right: 0;
    transform: translateY(-40%);
  }

  img {
    position: relative;
    box-shadow: 0px 2px 22px 0 rgba(1, 1, 1, 0.1);
    background: $white;
  }
}

.brands__page {
  #footer,
  #push {
    display: none;
  }

  .brand__detail .products__brand .product {
    margin-top: 0;
  }

  .products__brand .product .product__name {
    margin-top: 0;
  }
}

.brand__detail {
  overflow-y: scroll;
  background: $gray-100;

  .products__brand .product {
    max-width: 25%;
  }

  h2 {
    color: $beige_text;
    font-size: 26px;
    padding-bottom: 6rem;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 70px;
      height: 3px;
      background: $gray-200;
      bottom: 3rem;
      left: 0;
    }
  }

  h3 {
    font-size: 2rem;
    color: $green_new;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .results_list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    width: 100%;

    li {
      flex: 1 0 25%;
      max-width: 25%;
      padding: 10px;

      a {
        border: 1px solid $gray-200;
        display: flex;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        color: inherit;
      }
    }
  }

  address {
    font-family: $neris;
  }
}

.brand__detail,
.product__detail {
  .description {
    font-size: 1.8rem;
    font-family: $neris;
    color: $gray-600;
  }

  ul.list {
    padding-left: 30px;
    list-style-type: none;

    li {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        bottom: 5px;
        height: 10px;
        width: 10px;
        background: $white;
        border: 2px solid $green_new;
        border-radius: 50%;
      }
    }
  }
}

.products__brand {
  display: flex;
  flex-wrap: wrap;

  .product {
    flex: 1 0 25%;
    padding: 1rem;

    a {
      display: block;
      color: inherit;
    }

    .img__wrapper {
      // border: 1px solid $gray-200;
    }

    .product__name {
      padding: 1rem;
    }
  }
}

.adherent, .page-become-a-member {
  h2 {
    color: theme-color("primary");
  }
  #wrap {
    background-color: #f8f9fa;
  }
  #footer {
    background-color: #fff;
  }
}

.subscriber__discrption {
  font-weight: bold;
  padding: 3rem;
  background-color: rgba(theme-color("info"), 0.2);
}
.ingredients-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 2rem;

  li {
    border-bottom: 1px solid #ddd;
    position: relative;
    padding: 1rem;

    transition: all 0.3s ease;
    span {
      cursor: pointer;
      position: relative;
      padding-left: 3rem;
      &::before {
        content: "";
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 8px;
        border: 2px solid #d874ac;
        left: 10px;
        top: 50%;
        margin-top: -4px;
      }
    }
    .d-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
    }

    a.download {
      margin-bottom: 0 !important;
    }
    &:nth-child(odd) {
      background: #eee;
    }
    &:hover {
      background-color: rgba(theme-color("primary"), 0.5);
    }
  }
}

.contact__list {
  padding: 3rem;
  padding-left: 4rem;
  list-style-type: none;

  li {
    position: relative;

    a {
      color: $text-color;
      font-weight: bold;
      transition: all 0.3s ease;
      &:hover {
        color: theme-color("primary");
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: -2.5rem;
      bottom: 2px;
    }

    &.tel,
    &.telephone {
      &::before {
        @include sprite($sprite-tel);
      }
    }

    &.fax,
    &.Fax {
      &::before {
        @include sprite($sprite-fax);
      }
    }

    &.mail,
    &.email {
      &::before {
        @include sprite($sprite-mail);
      }
    }

    &.site,
    &.site-web,
    &.site_web {
      &::before {
        @include sprite($sprite-monitor);
      }
    }
  }
}

.logo__label {
  margin-top: 3rem;

  p {
    margin-top: 0;
    font-size: 2rem;
    color: $beige_text;
    font-family: $neris;
  }

  img {
    max-width: 70px;
    height: auto;
    margin-left: 2rem;
    display: inline-block;
    box-shadow: none !important;
  }
}

#id_page_menu {
  .content ul {
    list-style-type: none;
    padding-left: 0;

    img {
      width: 100%;
      height: auto;
    }

    li {
      a {
        display: block;
        background: $white;
        margin-bottom: 3rem;
        position: relative;
        background: $black;

        img,
        .description {
          transition: $transition;
        }

        .text {
          padding: 2rem;
          font-size: 2rem;
          font-family: $neris;
          display: block;
          text-align: center;
          background: $white;
        }

        .description {
          position: absolute;
          top: 10px;
          left: 10px;
          right: 10px;
          padding: 20px;
          color: $white;
          text-shadow: 1px 1px 2px black;
          opacity: 0;
          visibility: hidden;
          font-size: 20px;
        }

        &:hover {
          img {
            opacity: 0.6;
          }

          .description {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

/*--------------------
Adhésion
----------*/

#id_inscription_introduction {
  header.header__site {
    &.header__fixed {
      .menu__products {
        display: none !important;
      }

      .logo {
        img {
          width: 150px;
          height: auto;
        }
      }
    }
  }
}

.subscription__page {
  background-color: $gray-100;

  .bg {
    background-color: $green_new;
  }

  .language-tabs {
    display: flex;
    border-bottom: 0rem;
    margin-bottom: -1px;
    position: relative;
    z-index: 2;

    li {
      a {
        display: inline-block;
        padding: 0.5rem 1rem;

        &.active {
          font-family: $neris;
          color: $text-color;
          border: 1px solid #ccc;
          border-bottom-color: $white;
        }
      }
    }
  }

  .django-ckeditor-widget {
    width: 100%;
    display: block;
  }

  .help-block {
    margin-top: 1rem;
    color: $blue;
  }
}

.breadcrumb-block {
  margin-top: 2em;
  width: 100%;

  .separator {
    display: none;
  }
}

#id_archive {
  .side-bar {
    position: sticky;
    top: 200px;

    .tab-month {
      padding: 10px;
      background: $beige;

      a {
        color: $white;

        &:hover {
          color: $beige_text;
        }
      }

      > ul {
        > li {
          font-family: $neris;
        }
      }

      ul ul {
        border-top: 1px solid darken($beige, 10);
        margin-top: 10px;
        padding-top: 10px;
      }
    }
  }
}

.archive-content {
  .articles {
    display: flex;
    flex-wrap: wrap;

    a {
      color: inherit;
      display: block;
    }

    .article-event-date {
      span {
        &::after {
          content: "\00a0";
        }
      }
    }

    .article {
      width: 33.333%;
      padding: 30px;
      position: relative;
      color: inherit;
      background: #fff;
      min-height: 300px;
      cursor: pointer;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: $transition;

      &::before {
        position: absolute;
        content: "";
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        border: 1px solid rgba(74, 74, 74, 0.075);
        transition: opacity 0.3s;
        pointer-events: none;
      }

      h2 {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $gray-700;
      }

      small {
        color: $gray-500;
      }

      .img-wrapper {
        width: 100px;
        height: 100px;
        margin: 0 auto 20px;

        img {
          max-width: 100%;
          border-radius: 50%;
        }
      }

      &:hover {
        //transform: scale(1.1);
        z-index: 1;

        &::before {
          border-color: rgba($beige, 0.6);
          border-width: 3px;
        }
      }
    }
  }
}

#id_news {
  .intro p {
    font-size: 1.8rem;
  }

  h2 {
    color: $beige_text;
  }
}
.news--title {
  margin-bottom: 20px;
  h1 {
    margin-bottom: 10px;
  }
  .articledetail--date {
    font-weight: bold;
  }
}
.news--content {
  display: flex;

  .article--body {
    width: 70%;
    padding: 10px 30px;
    padding-left: 10px;
    border-right: 1px solid #eee;
    margin-right: 10px;

    .img-thumbnail-wrapper {
      margin-bottom: 3rem;
    }
  }

  .article--side-bar {
    padding: 10px;
    width: 30%;
    height: 400px;
    position: sticky;
    top: 130px;

    h2 {
      margin-bottom: 20px;
    }

    .news__sidebar {
      margin-bottom: 30px;
    }

    a.item {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      color: inherit;

      .img-container {
        width: 100px;
        max-width: 100px;
        margin-right: 20px;

        img {
          border-radius: 50%;
        }
      }

      h3 {
        width: 100%;
        color: $gray-600;
      }
    }

    .social-share-buttons {
      .social-share-button {
        margin-right: 1em;
      }
    }

    .date-range {
      font-size: 1.5em;
      color: $beige_text;

      span::after {
        content: " ";
      }
    }

    .author {
      .written {
        color: $beige_text;
        font-family: $neris;
        font-size: 2.2rem;
      }
    }
  }
}

.registration {
  position: relative;

  h1,
  legend {
    color: $green;
  }

  fieldset {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      background: url("../img/plante.png");
      height: 120px;
      width: 129px;
      right: -60px;
      top: -90px;
    }
  }
}

/*---------------------------
membres space
---------------------------*/
.espace_membres__topmenu {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $gray-200;
  padding-bottom: 0;

  a {
    color: $text-color;
    display: block;
    padding: 10px;

    &:hover {
      color: $beige_text;
    }
  }

  li {
    margin-right: 20px;
    position: relative;
    margin-bottom: -1px;

    &.active {
      border-bottom-color: $white;

      &::before {
        width: 100%;
        height: 3px;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $beige;
      }

      a {
        color: $beige_text;
        font-family: $neris;
      }
    }
  }
}

#id_adherent {
  .title {
    padding: 1rem;
    padding-bottom: 0;
    background: $white;
  }

  h1 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  #wrap {
    margin-left: 315px;
    padding: 3rem !important;
    padding-top: 0rem !important;
  }

  form.adherent__form .form__wrapper {
    box-shadow: none;
  }
}

.header__pro {
  width: 315px;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: -8px 0 42px 0 rgba(42, 34, 64, 0.14);

  .menu {
    list-style-type: none;
    padding: 2rem;

    li {
      padding-top: 10px;
      padding-bottom: 10px;

      a {
        color: $text-color;

        i {
          display: inline-block;
          margin-right: 1rem;
        }
      }
    }
  }
}

@media screen and (max-height: 940px) {
  .header__pro {
    overflow-y: scroll;
  }
}


.logo__adherent {
  padding: 1rem;
  background: $green_new;
  color: white;
  font-size: 15px;
  display: flex;
  align-items: center;

  img {
    width: 80px;
    height: auto;
    margin-right: 20px;
  }

  .name {
    text-transform: capitalize;
    font-family: $neris;
  }
}

.widget__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .widget {
    flex: 1 0 32%;
    max-width: 32%;

    @media (min-width: 800px) and (max-width: 1300px) {
      flex: 1 0 49%;
      max-width: 49%;
    }

    @media (max-width: 800px) {
      min-width: 100%;
    }
  }
}

.widget {
  padding: 3rem;
  margin-bottom: 3rem;
  background: $white;
  box-shadow: -8px 0 42px 0 rgba(42, 34, 64, 0.14);
  border-radius: 4px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  color: inherit;

  &.purple {
    background: $green_new;
  }

  &.purple,
  &.yellow,
  &.pink,
  &.cyan {
    color: $white;

    h2,
    a {
      color: $white;
    }
  }

  &.yellow {
    background: $beige;

    .icon__wrapper {
      color: $beige_text;
      background: $white;
    }
  }

  &.cyan {
    background: $green_new;
    color: $white;

    .icon__wrapper {
      color: $green_new;
      background: $white;
    }
  }

  &.pink {
    background: $beige;

    .icon__wrapper {
      color: $beige_text;
      background: $white;
    }
  }

  .icon__wrapper {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: $gray-300;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $green_new;
  }

  p {
    margin-top: 2rem;
  }

  h2 {
    color: $green_new;
    font-family: $neris_light;
    padding-right: 3rem;
    padding-left: 3rem;
    padding-bottom: 3rem;
    position: relative;
    text-align: center;

    span {
      margin-top: 2rem;
      display: block;
      font-size: 3rem;
      font-family: $neris;
    }

    &::after {
      width: 200px;
      height: 1px;
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background: $gray-200;
    }
  }
}

.detail__address {
  address {
    padding: 3rem;
    margin-right: 2rem;
  }
}

.contacts__details{
  display: flex;

  .contact-details{
    padding-right: 2rem;

    .contact__list{
      padding-top: 1rem;
    }
  }
}

.detail__title {
  margin-bottom: 3rem;

  h1 {
    margin: 0;
  }
}

.marque__detail {
  h2 {
    color: $beige_text;
  }

  .products__brand .product {
    margin-top: 0;
  }
}

.membres__space .container-fluid:not(.cms-content) {
  ul {
    @extend .list-unstyled;
  }
}

.ag_footer{
  background-color: darken(theme-color('light'), 10);
  span{
    font-family: $neris;
    font-size: 22px;
  }
}

.members-ca-about-title {
  margin-bottom: 1em;
}

#id_adherent{
  .grid.d-flex{
    flex-wrap: wrap;
    >div{
      @include make-col-ready;
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

    }
  }
}
#id_membres_ca{
  display: grid;
  grid-template-columns:  repeat(auto-fill, minmax(250px, 1fr));;
  gap: 10px;
  li{
    text-align: center;
    padding-top: 1rem;
    border: 1px solid #eee;
    background-color: #f5F6F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;

    video{
      margin-bottom: 1rem;
    }
    .name{
      font-size: 18px;
      font-family: $neris;
      padding: 1rem;
      text-transform: uppercase;
    }
    .user_img{
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: white;
      padding: .5rem;
      border: 1px solid #eee;
      margin-top: -60px;
    }
    img{
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 10px;
      width: 150px;
      height: auto;
    }
    .btn{
      margin-bottom: 20px;
    }
    .footer_li{
      background-color: theme-color('dark');
      padding: 10px;
      width: 100%;
      margin-top: auto;
      label{
        color: white;
      }
    }
  }
}


.polling_intro{
  font-size: 18px;
  ul{
    padding-left: 40px;
    list-style: disc;
  }
}

#div_id_confirmation{
  padding: 2rem;
  border: 1px solid #e2e2e2;
  background: #eee;
  .controls {
    display:flex;
    align-items: center;
  }
}


#id_pre_adhesion{
  .sign{
    display: none !important;
  }
  .nav-tabs{
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 0 !important;
    gap :1rem;
    margin-bottom: 2rem;
    li{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav-link{
      background: #E9ECEF ;
      text-align: center;
      height: 100%;
      padding: 2rem;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      color: inherit !important;
      font-weight: bold;
      font-size: 18px;
      &.active{
        border: 0  !important;
        background-color:$green_new !important;
        color: white !important;
      }
    }
  }
  .tab-content{
    padding: 2rem;
    p{
      padding-left: 20px;
      strong{
        position: relative;
        &::before{
          font-weight: 400;
          content: "\2192";
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          position: absolute;
          left: -20px;
          color: #94989E;
        }
      }
    }
  }
}

#id_products, #id_subscribers, #id_brands {
  h2 {
    font-size: 1.8rem;
    font-weight: normal;
  }
}
