a{
    transition: $transition;
    &:hover{
        text-decoration: none;
    }
}

h1, .h1{
    font-family: $neris;
    font-size: 3.2rem;
    margin-bottom: 4rem;
    margin-top: 3rem;
    color: $green_new;
}
h2{
    font-family: $neris;
    font-size: 2.2rem;
}

h3{
    font-family: $neris;
    font-size: 2rem;
}


h4, h5, h6{
    font-family: $neris;
    font-size: 1.9rem;
    color: $beige_text;
}

strong{
    font-weight: normal;
    font-family: $neris;
}
