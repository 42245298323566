input {
  font-size: 1.6rem !important;
  &:not([type]),
  &[type='text'],
  &[type='tel'],
  &[type='email'],
  &[type='number'],
  &[type='url'],
  &[type='password'] {
    border: 1px solid $gray-500;
    border-radius: 2px;
    height: 40px;
  }
  &[type='file'] {
    border: 0;
  }
  &:focus {
    outline: none;
    border-color: $beige;
  }
}

input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  &:focus + label {
    outline: 1px dotted $green_new;
    outline: -webkit-focus-ring-color auto 5px;
  }
}

input[type='file'] + label {
  color: white;
  background-color: $beige;
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

label,
.label {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.2s ease-out;
  font-family: $neris;
}

select {
  min-height: 40px !important;
  line-height: 25px !important;
  padding: 8px;
  font-size: 1.4rem !important;
  height: auto !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ddd;
  background-image: url('../img/icons/caret.png') !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 13px) 17px;
}

fieldset {
  padding: 3rem;
  margin-bottom: 2rem;
  border: 1px solid $gray-300;
  padding-top: 1rem;

  &.vertical {
    label,
    input {
      display: block;
      width: 100%;
    }
    label {
      margin-top: 1rem;
    }
  }
}

legend {
  padding: 2rem;
  font-size: 2rem;
  color: $green_new;
  font-family: $neris;
}

textarea {
  min-height: 70px;
  border: 1px solid #dddddd;
}

.has-error {
  label,
  .error {
    color: $red !important;
  }
  input,
  select {
    border: 1px solid rgba($red, 0.1) !important;
    box-shadow: 0 2px 15px 0 rgba($red, 0.1);
  }
}

#div_id_new_password1{
  .controls {
    ul{
      padding-left: 40px;
      list-style-type: disc;
    }
  }
}

.cos__checkbox,
.cos__radio,
input[type='checkbox'],
input[type='radio'] {
  display: inline-block;
  height: 16px;
  width: 16px;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: $white;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 1px solid #888;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
  margin-right: 10px;
  &:checked {
    background-color: $beige;
    border: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
  &:focus {
    outline: none;
    border-color: $beige;
  }
  &::checked + label {
    color: $beige_text;
  }
  + label {
    display: inline-block;
  }
}

select.form-control {
  border-color: $gray-500;
}

ul {
  &.form-control {
    border: 0;
    background-color: transparent;
  }
}

.form-control {
  font-size: 1.6rem;
  height: auto;
}

.cos__checkbox,
input[type='checkbox'] {
  border-radius: 2px;
  &:checked {
    background-image: url(../img/icons/check.png);
  }
}

.cos__radio,
input[type='radio'] {
  border-radius: 50%;
  &:checked {
    background-image: url(../img/icons/radio.png);
  }
}

.full__width {
  width: 100%;
  padding: 2rem;
  margin-bottom: 10px;
  border-bottom: 1px solid $gray-200;
  position: relative;
}

form.adherent__form {
  margin: 1rem;
  .border {
    border-style: dashed !important;
    background: $gray-100;
    border-width: 1px !important;
    border-color: $gray-500 !important;
  }
  .cke,
  .django-ckeditor-widget {
    max-width: 100% !important;
    width: 100% !important;
  }
  .language-tabs {
    margin-bottom: -1px;
    position: relative;
    z-index: 1;
    li {
      border-bottom: 0;
      a {
        padding: 10px;
        display: inline-block;
        color: $text-color;
        &.active {
          color: $beige_text;
        }
      }
      &:last-child {
        border-left: 0;
      }
    }
  }
  .form__wrapper {
    background: $white;
    box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 2rem;
    border: 1px solid $gray-300;

    .form-group{
      &.adherent-raison_sociale {
        flex-basis: 100%;
      }

      &.adherent-statut_juridique_autre  {
        visibility: hidden;
      }
    }
  }
  .optional {
    font-size: 1rem;
  }
  .date {
    display: flex;
    justify-content: space-between;
    select {
      width: 30%;
      height: auto !important;
      min-height: auto !important;
    }
  }
  .fieldset__wrapper {
    display: flex;
    flex-wrap: wrap;
    .form-group {
      flex: 1 0 50%; //max-width: 50%;
      padding: 1rem;
      &.contacts-0-charge_dossier,
      &.contacts-0-inscrit_newsletter {
        min-width: 100%;
      }
    }

    &.adhesion-ca{
      .form-group {
        flex-basis: 100%;
      }
    }

    &.marques-formset{
      .form-group-logo, .form-group-description{
        flex-basis: 100% !important;
      }

      .language-tabs {
        display: flex;
        border-bottom: 0rem;
        margin-bottom: -1px;
        position: relative;
        z-index: 2;

        li {
          a {
            &.active {
              font-family: $neris;
              border: 1px solid #ccc;
              border-bottom-color: $white;
            }
          }
        }
      }

      .form-group-description {
        .form-group{
          padding: 0;
        }
      }

    }

  }

  .formset__item {
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    margin-bottom: 15px;
    &:nth-child(odd) {
      background: $gray-100;
    }
    &:nth-child(even) {
      border: 1px solid $gray-200;
    }
  }
  select#id_referentiel_certification_3-organismes_certificateurs {
    min-height: auto !important;
  }
  .produits-formset {
    border: 1px solid #eee;
    padding: 1rem;
  }
}

.remove__field {
  position: absolute;
  right: -20px;
  top: -20px;
  background-color: lighten($red, 5);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  cursor: pointer;
}

.produit__item {
  position: relative;
  padding: 10px;
  border: 1px solid #eee;
  margin-bottom: 10px;

  #div_id_cible, [id^="div_id_produits-"][id$="-cible"] {
    #id_cible, [id^="id_produits-"][id$="-cible"] {
      li.unavailable .clible-label{
        text-decoration-line: line-through;
        opacity: 0.5;
      }
    }
  }

}

#id_adherent-activites {
  list-style-type: none;
}

.controls {
  ul {
    padding-left: 0;
    list-style-type: none;
  }
  label {
    margin-right: 10px;
    display: block;
  }
}

.cible {
  ul.form-control {
    display: flex;
    flex-wrap: wrap;
    li {
      flex: 1 0 50%;
    }
  }
}

.amount__wrapper {
  margin-bottom: 10px;
  background-color: $beige;
  padding-left: 1rem;
  display: flex;
  color: $white;
  font-family: $neris;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  [data-content] {
    margin-left: 3rem;
    font-size: 3rem;
    padding: 1rem 3rem;
    background: darken($beige, 10);
    min-width: 230px;
    text-align: center;
  }
  &.ht {
    background-color: $gray-300;
    color: $gray-700;
    [data-content] {
      background: $gray-400;
    }
  }
}

select[class*='certificats-'] {
  background: red;
  .controls {
    label {
      min-width: 100%;
      display: block;
    }
    select {
      width: 33%;
      float: left;
    }
  }
}

.recap {
  h3 {
    margin-top: 3rem;
    &:first-child {
      margin-top: 0;
    }
  }
  &__item {
    justify-content: space-between;
    padding: 10px;
    font-family: $neris;
    border-bottom: 1px solid #eee; // &:nth-child(odd){
    //     background: #eee;
    // }
  }
  .recap__item__field {
    width: 60%;
    color: $gray-600;
  }
  .recap__item__label {
    color: $green_new;
  }
}

.errorlist {
  list-style-type: none;
  padding-left: 0;
  color: rgba($red, 0.9);
}

.organismes-certificateurs {
  margin-left: 30px;
  margin-top: -15px;
}

.ca-export-continents {
  display: none;
}

.sous-categories {
  margin-left: 30px;
  margin-top: -20px;
}

.popover-body, .popover-header{
  font-size: 1.3rem;
}

.form__questions {
  border: 1px solid #eee;
  width: 100%;
  margin-bottom: 10px;
  .notation{
    text-align: right;
    font-size: small;
    background: $green_new;
    color: $white;
  }

  p:not(.error) {
    font-size: 2rem;
    background: $green_new;
    color: $white;
    margin-bottom: 0;
  }
  .responses {
    padding: 10px;
    border-top: 1px solid #eee;
    background: darken($white, 4);
  }
}

.membres__space {
  form.adherent__form {
    margin: 0;
    .form__wrapper {
      padding: 0;
      fieldset {
        margin: 0;
      }
    }
    .fieldset__wrapper :not(.adhesion-ca) {
      .form-group {
        flex: 1 0 33%;

        &.marques-nom {
          flex: 1 0 100%;
        }
      }
    }
  }

  .adherent-users-permissions-link {
    margin-top: -15px;
  }

  .adherent-users-actions{
    a{
      display: inline-block;
    }

    a:not(last-child){
      margin-right: 10px;
    }
  }
}
.radio__input {
  padding-left: 20px;
}

.inline__label {
  .controls {
    label {
      display: inline-block;
    }
  }
}

.page-id_contact, .page-id_contact_adhesion{
  form {
    background: #f5f5f5;
    border: 1px solid #eee;
    padding: 10px;
    margin-top: 16px;
    @include make-row();

    .form-group{
      @include make-col-ready();
      &.form-text-wrapper, &.form-email-wrapper, &.form-select-wrapper{
        @include media-breakpoint-up(sm) {
          @include make-col(6);
        }

        &.form-element-wrapper-objet_demande, &.form-element-wrapper-request_purpose{
          @include media-breakpoint-up(sm) {
            @include make-col(12);
          }
        }
      }
    }
    .form-required-field-message-wrapper, .form-button-wrapper{
      @include make-col-ready();
    }
    .required-field-message{
      padding: .5rem 1rem;
      margin-bottom: 1rem;
      background-color: rgba($primary, .2);
      color: $primary;
      border-radius: 2px;
    }
  }
}

#id_form_entry_submitted{
  .notifications{
    display:none;
  }
}
