.steps {
  background: $gray-100;
  display: flex;
  justify-content: center;
  // border-top: 1px solid $gray-200;
  //border-bottom: 1px solid $gray-200;
  padding-left: 0;
  flex-direction: column;
  .step {
    padding: 0.5rem;
    background: $gray-100;
    display: flex;
    min-height: 60px;
    align-items: center;
    text-align: center;
    color: $gray-900;
    font-weight: bold;
    &:last-child {
      border-right: 1px solid $gray-200;
    }
    &.ok {
      color: $green_new;
      font-family: $neris;
    }
    &.step-todo {
      opacity: 0.3;
    }

    a {
      display: flex;
      align-items: center;
    }
  }

  i.state {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid $gray-100;
    border-radius: 50%;
    margin-right: 10px;

    &.ok {
      background: $green_new;
      border-color: darken($green_new, 5);
      background-image: url(../img/icons/check.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .step-number {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    display: inline-block;
    background-color: $gray-300;
    border-radius: 20px;
    font-size: 1.6rem;
    line-height: 4rem;
  }
}

.wizard-tracking-progress.sticky-top {
  top: 200px;
}
