.promotion-image{
    height: 650px;
    background-size:cover;
    background-position:center;
}
#promotion_image{
    .carousel-indicators{
        bottom: 80px;
    }
}
.carousel{
    .overlay{
        position: absolute;
        z-index: 999;
        bottom: 0px;
        left: 10%;
        max-width: 600px;
        padding: 10px;
        h2{
            color: $white;
            font-family: $gunny;
            font-size: 6rem !important;
            margin-bottom: 6rem ;
            line-height: 5rem;
        }
    }
}
.carousel-inner{
    overflow: hidden;

    .slide-wrapper {
        padding-bottom: 50px;
    }
}

.carousel__link{
    background: $green_new;
    padding: 3rem 6rem;
    color: $white;
    display: inline-block;
    font-size: 2rem;
    font-family: $neris;
    i{
        margin-left: 1.4rem;
    }
}

.carousel-indicators li{
    cursor: pointer;
}
