#footer{

    overflow: hidden;
}
.footer__site {
    padding: 4rem;
    display: flex;
    margin: 0 auto;
    img {
        width: 150px;
    }
    ul {
        display: flex;
        list-style-type: none;
        padding-left: 0;
        flex-direction: column;
    }
    a {
        color: $black;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: bold;
        i {
            font-size: 22px;
            color: $gray-700;
            transition: $transition;
        }
        &:hover {
            color: $beige_text;
            font-family: $neris;
            i {
                color: $beige_text;
            }
        }
    }
    .nav {
        li {

            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .social__links{
        ul{
            flex-direction: row;
        }
    }
}

#footer.footer {
    border-top: 1px solid $gray-200;
}

.footer,
#push {
    // height: 330px;
}

.legal-notices {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 14px;
        background-image: url(../img/icons/waves.png);
    }
    li {
        padding: 10px;
    }
    a {
        color: $black;
    }
}
