.overflow{
    &__hidden{
        overflow: hidden;
    }
}
body.overflow{
    overflow: hidden;
}
.result__list__page{
    #footer,
    #push {
        display: none;
    }
    .pagination {
        margin-top: 3rem;
    }
    header.header__site {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        border-bottom: 1px solid #ccc;
    }
    .sidebar {
        position: fixed;
        top: 170px;
        bottom: 0;
        left: 0;
        z-index: 1000;
        padding: 20px 0;
        overflow-x: hidden;
        overflow-y: auto;
        border-right: 1px solid #eee;
        ul{
            list-style-type: none;
            padding-left: 0;
        }
        #id_name{
            width: 100% !important;
        }
    }
    main{
        padding: 0;
        overflow-y: scroll;
        border-right: 1px solid #eee;
    }
    .search-list__result-count {
        background: $gray-300;
        padding: 20px;
    }
    .result__item{
        border: 1px solid $gray-200;
        border-bottom: 0;
        border-right: 0;
        border-left: 0;
        padding-left: 20px;
        transition: $transition;

        &:first-child {
            border-top: 0;
        }
        &.selected,
        &:hover {
            background: $green_new;
            a {
                color: $white;
            }
        }
        img {
            width: 30px;
            height: auto;
            margin-right: 1rem;
        }
        a {
            display: flex;
            padding: 1rem;
            flex-direction: row;
            align-items: center;
            color: $gray-600;
        }
    }

    .result__list__detail{
        overflow-y: scroll;
        background: #f8f9fa;

        .detail {
            padding: 2rem 0;
        }
    }
}

#id_brands{
    .sidebar #id_name{
        min-width: 100%;
    }
}



.subscriber__title{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    h1{
        max-width: 60%;
        margin-top: 0;
        margin-bottom: 0;
    }
    img.subscriber__logo{
        max-width: 40%;
        height: auto;
    }
}

.categories__filter{
    .show{
        ul{
            @extend .list-group;
        }
    }
    li{

        a{
            color: $text-color;
            display: block;
            background-color: white;
            padding: 1rem 2rem;
        }
        &:not(:last-child){
            a{
                border-bottom: 1px solid #eee;
            }
        }
        &.selected{
            a{
                background: $green_new;
                color: white;
            }
        }
    }
}

.subcategory__filter {
    label {
        font-family: $neris_light;
    }
}

.adherent__brands--wrapper{
    background-color: $gray-200;
    padding: 4rem 1rem;
    margin-top: 10px;
    .adherent__brands{
        display: grid;
        gap: 1rem;
        margin-top: 10px;
        @include media-breakpoint-up(xs) {
            grid-template-columns: repeat(4, 1fr);
        }
        .brand{
            >a{
                display: block;
                text-align: center;

                background-color: white;
                padding: 10px;
                color: inherit;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
                .brand__name{
                    padding: 10px 0;
                    font-family: $neris;
                }
            }
        }
    }
}
