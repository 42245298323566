.widget-articles {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;

  a {
    color: inherit;
    display: block;
    padding: 30px;
  }

  .article-event-date {
    span {
      &::after {
        content: '\00a0';
      }
    }
  }

  .article {
    width: 33.333%;
    position: relative;
    color: inherit;
    background: #fff;
    min-height: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: $transition;
    &::before {
      position: absolute;
      content: '';
      top: 5px;
      right: 5px;
      bottom: 5px;
      left: 5px;
      border: 1px solid rgba(74, 74, 74, 0.075);
      transition: opacity 0.3s;
      pointer-events: none;
    }
    h2 {
      // flex: 1;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      width: 100%;
      font-size:20px;
      margin-top: 10px;
      margin-bottom: 10px;
      color: $gray-700;
    }
    small {
      color: $gray-500;
    }
    .img-wrapper {
      width: 100px;
      height: 100px;
      margin: 0 auto 20px;
      img {
        max-width: 100%;
        border-radius: 50%;
      }
    }
    &:hover {
      z-index: 1;
      &::before {
        border-color: rgba($beige, 0.6);
        border-width: 3px;
      }
    }
  }
}

.article--body {
  width: 70%;
  padding: 30px 10px 30px 0px;
  border-right: 1px solid #eee;
  margin-right: 10px;
  .img-thumbnail-wrapper {
    margin-bottom: 3rem;
  }
  @include media-breakpoint-down(sm){
    width: 100% !important;
    padding: 10px !important;
  }
}

.article--side-bar {
  padding: 10px;
  width: 30%;
  height: 400px;
  position: sticky;
  top: 130px;
  @include media-breakpoint-down(sm){
    width: 100% !important;
    padding: 20px !important;
  }
  h2 {
    margin-bottom: 20px;
  }
  .news__sidebar {
    margin-bottom: 30px;
  }
  a.item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: inherit;
    .img-container {
      width: 100px;
      max-width: 100px;
      margin-right: 20px;
      img {
        border-radius: 50%;
      }
    }
    h3 {
      width: 100%;
      color: $gray-600;
    }
  }
  .social-share-buttons {
    .social-share-button {
      margin-right: 1em;
    }
  }
  .date-range {
    font-size: 1.5em;
    color: $beige_text;

    span::after {
      content: ' ';
    }
  }
  .author{
    .written{
      color: $beige_text;
      font-family: $neris;
      font-size: 2.2rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .widget-articles {
    flex-direction: column;
    .article {
      width: 100%;
    }
  }

  .article--body {
    margin-right: 0;
    margin-bottom: 20px;
    border-right: 0;
  }

  .article--side-bar,
  .article--body {
    width: 100% !important;
    padding: 20px;
  }

  .article--side-bar {
    background-color: $gray-200;
    h2,
    h3 {
      color: $white !important;
    }
  }
}
