.row-background {
    padding-top: 15px;
    padding-bottom: 15px;

    h2:first-child,
    h3:first-child,
    h4:first-child {
        margin-top: 0;
    }

    a:hover {
        text-decoration: underline;
    }
    >.row{
        margin-left: 0;
        margin-right: 0;
    }
}

.column-background {
    padding: 15px;
    margin-bottom: 15px;
    overflow: hidden;

    p:last-child {
        margin-bottom: 0;
    }

    a:hover {
        text-decoration: underline;
    }
}

.white-background {
    color: $text-color;
    background-color: $white;
}

.green-background {
    color: $white;
    background-color: $green;

    a {
        color: $text-color;
    }

    ul {
        color: $white;
    }
}

.cyan-background {
    color: $white;
    background-color: $green_new;

    a {
        color: $text-color;
    }

    ul {
        color: $white;
    }
}

.pink-background {
    color: $white;
    background-color: $beige;

    a {
        color: $text-color;
    }

    ul {
        color: $white;
    }
}

.purple-background {
    color: $white;
    background-color: $green_new;

    a {
        color: $text-color;
    }

    ul {
        color: $white;
    }
}

.mauve-background {
    background-color: $green_extralight;
}

.gold-background {
    color: $white;
    background-color: $beige;

    a {
        color: $text-color;
    }

    ul {
        color: $white;
    }
}

.gray-background {
    background-color: $gray-600;

    a {
        color: $white;
    }
}

.column-spacer {
    width: 100%;
}

.column-spacer-sm {
    height: 2em;
}

.column-spacer-md {
    height: 4em;
}

.column-spacer-lg {
    height: 8em;
}
