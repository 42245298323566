.list-group-item {
    border: 0;
    background-color: $beige;
    color: $white;

    &.active {
        background-color: darken($beige, 10%);
        color: $white;
    }

    &:hover {
        background-color: $green_new;
        color: $white;
    }

    .fa {
        margin-right: .5em;
    }
}

.list-group-item-month {
    padding-left: 2em;
}

.list-group-title {
    display: block;
    margin-top: 1em;
}
#archives-button {
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
