.product-widget {
  display: grid;
  gap: 1rem;
  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media-breakpoint-only(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.product {

  transition: $transition;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  border: 1px solid #eee;

  span.brand__name {
    font-family: $neris_light_italic;
    position: relative;
    display: inline-block;
  }

  .content {
    transition: $transition;
  }

  .img__wrapper {
    background: $white;
    text-align: center;
    padding: 0.5rem;
    transition: $transition;
  }

  .product__name {
    font-family: $neris;
    font-size: 1.8rem;
    margin-top: 2rem;
    color: $beige_text;
    span {
      position: relative;
      display: inline-block;
    }
  }

  &:hover {
    transform: translateY(-10px);
    .img__wrapper {
      box-shadow: 0px 1px 20px 5px rgba($black, 0.1);
    }
    .product__name span,
    .brand__name {
      &::before {
        transform: scale3d(1, 1, 1);
      }
    }
  }

  a {
    color: inherit;
  }
}




.product__page{
  h1{
    margin: 0;
    margin-bottom: 1rem;
  }
  h2{
    margin-bottom: 1rem;
    color: inherit;
  }
  .contact__list{
    padding: 0;
    padding-left: 20px;
  }
  .product__wrapper{
    margin-top: 2rem;
    margin-bottom: 10rem;
    display: grid;
    gap: 2rem;
    align-items: center;
    @include media-breakpoint-up(md) {

      grid-template-columns: 40% 1fr;
    }
    .img__wrapper{
      background-color: $gray-100;
      padding: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.adherent{
    .product__wrapper{
      @include media-breakpoint-up(lg) {
        grid-template-columns: 20% 1fr;
        margin-bottom: 2rem;
      }
    }
  }
}
.product__categories{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  a{
    display: flex;
    background-color: theme-color('secondary');
    color: white;
    padding: .5rem 1rem;
    border-radius: 1rem;
    font-weight: bold;
    align-items: center;
    line-height: 1;
    font-size: 80%;
    transition: all .3s ease;
    &:hover{
      background-color: $green;
    }
  }
}

.product__label{
  font-weight: bold;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  img{
    max-width: 60px;
    height: auto;
  }
}
.product__target{
  margin-top: 2rem;
  p{
    font-weight: bold;
  }
  ul{
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
  }
}

.product__more--info{
  margin-top: 5rem;
  .nav-tabs{
    justify-content: center;
    border-bottom: 0;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    .nav-link{
      margin-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      color: inherit;
      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }
      &.active{
        font-weight: bold;
        background-color:rgba($green, 20%);
        border-bottom: 0;
        color: $green;
      }
    }
  }
  .tab-content{
    background-color:rgba($green,20%);
    padding: 3rem 0;
  }
}


.product__marque{
  span{
    font-size: 80%;
  }
}



.bg__green--light{
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color:rgba($green,20%);
  margin-bottom: 3rem;
}
